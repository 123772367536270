import type { Images } from '@neos/app'
import tmsAscBigDotPath from '../../modules/TmsAscTracking/images/TmsAscBigDot.svg'
import tmsAscCheckPath from '../../modules/TmsAscTracking/images/TmsAscCheck.svg'
import tmsAscDeliveryPath from '../../modules/TmsAscTracking/images/TmsAscDelivery.svg'
import tmsAscFlagPath from '../../modules/TmsAscTracking/images/TmsAscFlag.svg'
import tmsAscInTransitOrderStatusPath from '../../modules/TmsAscTracking/images/TmsAscInTransitOrderStatus.svg'
import tmsAscLoginPath from '../../modules/TmsAscTracking/images/TmsAscLogin.svg'

const images: Images = {
  tmsAscBigDot: { path: tmsAscBigDotPath, colored: false },
  tmsAscCheck: { path: tmsAscCheckPath, colored: false },
  tmsAscDelivery: { path: tmsAscDeliveryPath, colored: false },
  tmsAscFlag: { path: tmsAscFlagPath, colored: false },
  tmsAscInTransitOrderStatus: { path: tmsAscInTransitOrderStatusPath, colored: false },
  tmsAscLogin: { path: tmsAscLoginPath, colored: false },
}

export default images